import React from "react"

import Article from "../components/article"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Bio from "../components/Bio"
import Articles from "../components/Articles"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Article>
      <Bio />
      <h1>Articles</h1>
      <Articles />
    </Article>
  </Layout>
)

export default IndexPage
