import React from "react"
import { Link } from "gatsby"
import FixedSizeImage from "./fixedSizeImage"

const Bio = () => (
  <>
    <p>
      I'm Domenico De Felice,
      Software Engineer at &nbsp;
      <Link to="https://about.facebook.com"><FixedSizeImage path="facebook-wordmark-gray.png" alt="Facebook"/></Link>
    </p>
    <p>
      <strong>Lambda squared</strong> is my personal blog.
    </p>
    <p>
      Opinions are my own and not the views of my employer.
    </p>
    <p>
      <Link to="/contact/">Get in contact</Link>
    </p>
  </>
)

export default Bio
