import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const FixedSizeImage = ({ path, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { internal: { mediaType: { regex: "/image/" } } }) {
        nodes {
          relativePath
          childImageSharp {
            fixed(width: 300) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  `)

  const match = useMemo(
    () => data.allFile.nodes.find(({ relativePath }) => relativePath === path),
    [data, path]
  )

  if (match?.childImageSharp?.fixed == null) {
    return null
  }

  return <Img fixed={match.childImageSharp.fixed} {...props} />
}

export default FixedSizeImage;
