import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Post from "./Post"

export default function Articles() {
  return (
    <StaticQuery
      query={graphql`
        query AllArticlesQuery {
          allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
          ) {
            edges {
              node {
                frontmatter {
                  date
                  title
                  description
                  author
                  path
                  featuredImage {
                    childImageSharp {
                      fixed(height: 100) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data =>
        data.allMarkdownRemark.edges.map(post => {
          const {
            title,
            author,
            date,
            description,
            path,
            featuredImage,
          } = post.node.frontmatter

          return (
            <Post
              title={title}
              author={author}
              date={date}
              description={description}
              key={`${date}__${title}`}
              path={path}
              featuredImage={featuredImage}
            />
          )
        })
      }
    />
  )
}
