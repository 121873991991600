import PropTypes from "prop-types"
import React from "react"
import "./article.css"

const Article = ({ children }) => {
  return <div className="article">{children}</div>
}

Article.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Article
