import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import "./Post.css"

const Post = ({ title, author, date, description, path, featuredImage }) => {
  const img =
    featuredImage?.childImageSharp?.fixed == null ? null : (
      <Img fixed={featuredImage.childImageSharp.fixed} />
    )

  return (
    <Link to={path}>
      <div className="post">
        <div className="post-title">
          {title}
          <div className="post-date">{date}</div>
        </div>
        <div className="post-thumbnail">{img}</div>
      </div>
    </Link>
  )
}

export default Post
